<!-- eslint-disable vue/no-v-html -->
<template>
  <section id="card-content-types">
    <b-row>
      <b-col
        cols="11"
        class="mx-auto"
      >
        <b-card
          v-if="loading"
          class="mb-2"
        >
          <b-card-text class="px-5 py-2">
            <b-spinner
              label="Loading..."
              variant="info"
              class="center"
            />
          </b-card-text>
        </b-card>

        <b-card
          v-if="isNoRecord && loading == false"
          class="mb-2"
        >
          <b-card-text class="px-5 py-2">
            No records found.
          </b-card-text>
        </b-card>

        <div v-else>
          <b-card
            v-for="news in info"
            :key="news.id"
            class="mb-2"
          >
            <b-card-text class="px-5 py-2 mobile-card-text">
              <p class="float-right font-weight-bold">
                {{ moment(news.created_at).format('MMMM DD, YYYY') }} | {{ moment(news.created_at, "HH:mm:ss").format("LT") }}
                <b-badge
                  v-if="news.is_new"
                  class="ml-1 border rounded-pill text-white"
                  size="sm"
                  variant="success"
                >NEW</b-badge>
              </p>
              <h1 class="mb-4 pt-4 text-center">
                <router-link :to="`/product-updates/user-detail/${news.id}`">
                  {{ news.title }}
                </router-link>
              </h1>
              <div
                class="article-inner-body"
                v-html="news.content"
              />
            </b-card-text>
          </b-card>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <nav>
              <ul class="pagination">
                <li
                  :class="{disabled:!pagination.first_link}"
                  class="page-item"
                >
                  <a
                    href="#"
                    class="page-link"
                    @click="viewNews(pagination.first_link)"
                  >&laquo;</a>
                </li>
                <li
                  :class="{disabled:!pagination.prev_link}"
                  class="page-item"
                >
                  <a
                    href="#"
                    class="page-link"
                    @click="viewNews(pagination.prev_link)"
                  >&lt;</a>
                </li>
                <li
                  v-for="n in pagination.last_page"
                  :key="n"
                  :class="{active: pagination.current_page == n}"
                  class="page-item"
                >
                  <a
                    href="#"
                    class="page-link"
                    @click="viewNews(pagination.path_page + n)"
                  >{{ n }}</a>
                </li>
                <li
                  :class="{disabled:!pagination.next_link}"
                  class="page-item"
                >
                  <a
                    href="#"
                    class="page-link"
                    @click="viewNews(pagination.next_link)"
                  >&gt;</a>
                </li>
                <li
                  :class="{disabled:!pagination.last_link}"
                  class="page-item"
                >
                  <a
                    href="#"
                    class="page-link"
                    @click="viewNews(pagination.last_link)"
                  >&raquo;</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-md-6 text-right">
            Page: {{ pagination.from_page }} - {{ pagination.to_page }}
            Total: {{ pagination.total_page }}
          </div>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from '@/libs/axios'
import moment from 'moment'
import {
  BRow, BCol, BCard, BCardText, BBadge, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BSpinner,
    BCardText,
  },
  data() {
    return {
      info: [],
      pagination: {},
      loading: true,
      moment,
      isNoRecord: true,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  beforeMount() {},
  mounted() {
    this.viewNews()
  },
  created() {},
  methods: {
    viewNews(uri = '/api/news/list3') {
      this.loading = true
      axios.get(uri)
        .then(res => {
          // filter res.data.data to only contain Product Updates tag
          this.info = res.data.data
          for (const item of this.info) { // eslint-disable-line
            item.content = this.formatImage(item.content)
            if (item.is_new) this.refreshUserBadges(item)
          }
          this.pagination = {
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            from_page: res.data.from,
            to_page: res.data.to,
            total_page: res.data.total,
            per_page: res.data.per_page,
            path_page: `${res.data.path}?page=`,
            first_link: res.first_page_url,
            last_link: res.last_page_url,
            prev_link: res.prev_page_url,
            next_link: res.next_page_url,
          }
          if (this.info.length > 0) { this.isNoRecord = false }
          if (this.info.length === 0) { this.pagination = false }
          this.loading = false
        })
        .catch(error => { console.log(error) })
    },
    formatImage(content) {
      content = content.replaceAll("<img ", '<img style="max-width: 100%;height: auto" class="center" ') // eslint-disable-line
      content = content.replaceAll("<a href=\"../blog/2019/07/custom-offer-payout-per-user", "<a href=\"https\:\/\/www\.trafficmanager\.com\/blog\/2019\/07\/custom-offer-payout-per-user\/\"") // eslint-disable-line
      content = content.replaceAll("<a href=\"../blog/2019/07/campaigns-scheduling", "<a href=\"https\:\/\/www\.trafficmanager\.com\/blog\/2019\/07\/campaigns-scheduling\/\"") // eslint-disable-line
      return content
    },
    async refreshUserBadges(item) {
      const uri = `/api/news/mark_read/${item.id}`
      await axios.get(uri)
      this.$store.dispatch('home/fetchUserRefresh')
      this.$store.dispatch('home/fetchUserBadgesReset')
    },
  },
}
</script>
<style >
.mobile-card-text {
    text-align: justify;
  }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* iphone 12/13 pro max - android pro max*/
@media only screen and (max-width: 428px)  {
  .mobile-card-text {
    padding: 5px !important;
  }
  p img{
    height: 100% !important;
    width: 100% !important;
    object-fit: contain
  }
}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 415px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 395px)  {

}

/* iphone 12/13 pro - android pro*/
@media only screen and (max-width: 376px)  {

}

/* smaller phone */
@media only screen and (max-width: 365px)  {

}
</style>
